import { ReactComponent as RectanguleIcon } from '../assets/icons/rectangle45.svg';
import { ReactComponent as Money } from '../assets/icons/money1.svg';
import { ReactComponent as Communication } from '../assets/icons/comm2.svg';
import { ReactComponent as Knowledge } from '../assets/icons/knowledge3.svg';
import { ReactComponent as Config } from '../assets/icons/conf4.svg';
import { ReactComponent as Professional } from '../assets/icons/prof5.svg';

const Structure = () => {
  return (
    <div
      // ref={reference}
      className="pt-16 flex flex-col aling-center px-10 ">
      <h1 className="text-center text-[#20A4D5] pb-2 font-bold text-3xl md:text-[45px]">How we’re structured to be better than the rest</h1>
      <h2 className="text-center text-darkGray text-xl md:text-[28px] pb-10 md:pb-10">
        Two Lights is organized into two primary units that seamlessly work together
      </h2>
      <div className="md:px-[20%] mb-10">
        <div className="grid grid-cols-1 md:grid-cols-2 pb-10">
          <div className="flex flex-col md:w-[100%] " data-aos="fade">
            <div className="flex space-x-5 mb-2">

              <div>
                <RectanguleIcon />
              </div>
              <p className="text-xl md:text-[24px]">
                Act in an executive oversight role to manage all high-level client communications
              </p>
            </div>
            <div className="flex space-x-5 mb-2">
              <div>
                <RectanguleIcon />
              </div>
              <p className="text-xl md:text-[24px]">
                Manage sales, billing, client
                development, satisfaction, and other operational tasks
              </p>
            </div>
            <div className="flex space-x-5 mb-2">
              <div>
                <RectanguleIcon />
              </div>
              <p className="text-xl md:text-[24px]">
                Act as a sounding board for
                effective project execution
              </p>
            </div>
          </div>
          <div className="flex md:justify-end" data-aos="fade-left">
            <img src={require('../assets/images/usa.png')} alt="" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 pb-10">
          <div className="drop-shadow-2xl order-2 md:order-1" data-aos="fade-right">
            <img src={require('../assets/images/latam.png')} alt="" />
          </div>
          <div className="flex flex-col md:w-[100%] order-1 md:order-2 md:pt-10" data-aos="fade">
            <div className="flex space-x-5 mb-2">
              <div>
                <RectanguleIcon />
              </div>
              <p className="text-xl md:text-[24px]">
                Offer technical support to scope your project</p>
            </div>
            <div className="flex space-x-5 mb-2">
              <div>
                <RectanguleIcon />
              </div>
              <p className="text-xl md:text-[24px]">
                Manage the primary day-day
                development tasks
              </p>
            </div>
            <div className="flex space-x-5 mb-2">
              <div>
                <RectanguleIcon />
              </div>
              <p className="text-xl md:text-[24px]">
                Liaise with the US-based team to offer project updates and remain accountable to results
              </p>
            </div>
          </div>
        </div>

      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 pb-10 md:px-[15%] md:space-x-10 ">
        <div className="flex flex-col justify-center">
          <h1 className="text-center md:text-left text-blue font-bold text-2xl md:text-[35px] mb-5">How we mitigate your risks</h1>
          <p className="text-xl md:text-[24px] text-gray mb-5 ">
            We realize that finding a <strong>reliable</strong>, <strong>accountable</strong>, and <strong>transparent</strong>  offshore development team is difficult.
          </p>
          <p className="text-xl md:text-[24px] text-gray pb-4 ">
            <strong>Our services mitigate the risks associated with trusting your unique solution to external contractors.</strong>
          </p>
        </div>
        <div className="flex flex-col md:w-[100%] " data-aos="fade">
          <div className="flex space-x-5">
            <div>
              <Money />
            </div>
            <p className="text-xl md:text-[24px] text-gray">
              <strong className='text-[#20A4D5]'>Lack of Price Transparency - </strong>Clear pricing using pricing tool and technical panel input
            </p>
          </div>
          <div className="flex space-x-5">
            <div>
              <Communication />
            </div>
            <p className="text-xl md:text-[24px] text-gray">
              <strong className='text-[#20A4D5]'>Communication Breakdown - </strong>Consistent,
              predictable, and open communication
            </p>
          </div>
          <div className="flex space-x-5">
            <div>
              <Knowledge />
            </div>
            <p className="text-xl md:text-[24px] text-gray">
              <strong className='text-[#20A4D5]'>Knowledge Transfer -</strong> Clear understanding of features and back-end architecture
            </p>
          </div>
          <div className="flex space-x-5">
            <div>
              <Config />
            </div>
            <p className="text-xl md:text-[24px] text-gray">
              <strong className='text-[#20A4D5]'>Low Competency - </strong>Carefully vetted,
              talented, and experienced developers
            </p>
          </div>
          <div className="flex space-x-5">
            <div>
              <Professional />
            </div>
            <p className="text-xl md:text-[24px] text-gray">
              <strong className='text-[#20A4D5]'>Lack of Professionalism - </strong>Strong command
              of English language and understanding of
              domestic ways of business
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Structure;
