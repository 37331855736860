import { useEffect } from 'react'
import { Contact, Footer, Header } from '../components'
import Aos from 'aos';
import 'aos/dist/aos.css';
import ReactGA from 'react-ga';

export const ContactPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Header scroll={() => { }} isContactPage={true} />
      <Contact />
      <Footer scroll={() => { }} isContactPage={true} />
    </>
  )
}
