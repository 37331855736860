import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FormSubmission = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <div className="w-full flex justify-center py-16 mb-20 ">
        <div className="w-[80%] max-w-screen-xl" data-aos="fade-up">
          <div style={{ height: '100px' }}></div>
          <h1 className="text-center text-blue font-bold text-[45px]">
            Thank you for your submission. We will be in touch shortly!
          </h1>

          <div className="w-full flex justify-center py-16 mb-20 ">
            <button
              onClick={() => navigate('/')}
              className="w-[100%] md:w-[400px] py-2 px-16 text-white text-xl font-bold bg-gradient-to-r from-blue to-lightBlue rounded-full shadow-xl hover:bg-gradient-to-r hover:from-lightBlue hover:to-sky hover:bg-sky ">
              <h1 className="   pr-5 text-lg md:text-xl text-white font-bold">{'Take Me Home'}</h1>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSubmission;
