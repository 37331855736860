import { useEffect } from 'react';
import { FormSubmission, Footer, Header } from '../components';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ReactGA from 'react-ga';

export const FormSubmissionPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Header scroll={() => {}} isContactPage={true} />
      <FormSubmission />
      <Footer scroll={() => {}} isContactPage={false} />
    </>
  );
};
