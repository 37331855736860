import { ReactComponent as ServiceIcon1 } from '../assets/icons/servicesIcons/service1.svg';
import { ReactComponent as ServiceIcon2 } from '../assets/icons/servicesIcons/service2.svg';
import { ReactComponent as ServiceIcon3 } from '../assets/icons/servicesIcons/service3.svg';
import { ReactComponent as ServiceIcon4 } from '../assets/icons/servicesIcons/service4.svg';
import { ReactComponent as ServiceIcon5 } from '../assets/icons/servicesIcons/service5.svg';
import { ReactComponent as ServiceIcon6 } from '../assets/icons/servicesIcons/service6.svg';
import { ReactComponent as ServiceIcon7 } from '../assets/icons/servicesIcons/service7.svg';
import { ReactComponent as ServiceIcon8 } from '../assets/icons/servicesIcons/service8.svg';
import { ReactComponent as ServiceIcon9 } from '../assets/icons/servicesIcons/service9.svg';


const Services = ({ reference }: { reference: React.MutableRefObject<HTMLDivElement | null> }) => {
  return (
    <>
      <div
        ref={reference}
        className="w-full md:h-[1050px] pb-10 flex flex-col justify-center items-center md:bg-services-bg bg-cover bg-no-repeat">
        <div className="w-[80%] md:max-w-screen-xl grid grid-cols-1 md:grid-cols-2">
          <div className="md:w-[80%] flex flex-col justify-center">
            <h1 className="text-center md:text-left text-blue font-bold text-4xl md:text-[68px] mb-5">Our Services</h1>
            <p className="text-xl md:text-[28px] text-gray">
              {/* We provide agile software development resources in several languages, though our team specializes in JavaScript.
              We also offer: */}
              We provide agile software development resources in several languages, specializing in JavaScript. 
              We also offer:
            </p>
          </div>
          <div className='grid grid-cols-2 md:grid-cols-3' data-aos="fade-left">
            <ServiceCard text='UX/UI consulting'>
              <ServiceIcon1 />
            </ServiceCard>
            <ServiceCard text='Web design'>
              <ServiceIcon2 />
            </ServiceCard>
            <ServiceCard text='Artificial intelligence (including GPT -4 applications)'>
              <ServiceIcon3 />
            </ServiceCard>
            <ServiceCard text='Machine learning development'>
              <ServiceIcon4 />
            </ServiceCard>
            <ServiceCard text='Big data engineering'>
              <ServiceIcon5 />
            </ServiceCard>
            <ServiceCard text='Staff augmentation services'>
              <ServiceIcon6 />
            </ServiceCard>
            <ServiceCard text='Digital transformation consulting'>
              <ServiceIcon7 />
            </ServiceCard>
            <ServiceCard text='Cloud computing'>
              <ServiceIcon8 />
            </ServiceCard>
            <ServiceCard text='Blockchain and web 3.0 technologies'>
              <ServiceIcon9 />
            </ServiceCard>
          </div>
        </div>
      </div>
    </>

  );
};

export default Services;

type Props = {
  children: JSX.Element,
  text: string,
}

const ServiceCard = ({ children, text }: Props) =>
  <div key={text} className="flex flex-col justify-center items-end m-2">
    <div className="backdrop-blur-md border border-[#E9E9E9] rounded-[20px] w-[130px] h-[135px]  md:w-[164px] md:h-[165px] flex flex-col items-center justify-center text-center p-2">
      <div className='h-[50%]'>
        {children}
      </div>
      <p className="text-blue font-bold text-sm md:text-md">{text}</p>
    </div>
  </div>