import { useEffect, useState } from 'react';
import { ReactComponent as HomeImg } from '../assets/images/home.svg';
import { useNavigate } from 'react-router-dom';

type Props = {
  reference: React.MutableRefObject<HTMLDivElement | null>;
  scroll: (ref: string) => void;
};
const Home = ({ reference, scroll }: Props) => {
  const navigate = useNavigate()
  const [buttonText, setButtonText] = useState('Learn More')

  useEffect(() => {
    if (window.innerWidth < 768) setButtonText('Learn More')
  }, [])

  

  return (
    <div
      ref={reference}
      className="w-full pt-20 flex flex-col justify-center items-center bg-home-bg bg-cover bg-no-repeat"
    >
      <div className="w-[80%] max-w-screen-xl grid grid-cols-1 md:grid-cols-2 ">
        <div>
          <h1 className="font-bold text-3xl md:text-5xl text-blue pb-5 ">
            Hire qualified software development teams for your
            <a className="text-sky"> next project</a>
          </h1>


          <p className="text-xl md:text-2xl text-darkGray pb-7">
            We provide custom-built, market-leading software development teams comprised of
            affordable, offshore resources to achieve our client’s vision.
          </p>
          <button
            onClick={() => navigate('/contact')}
            className="w-[100%] md:w-[400px] py-2 px-16 text-white text-xl font-bold bg-gradient-to-r from-blue to-lightBlue rounded-full shadow-xl hover:bg-gradient-to-r hover:from-lightBlue hover:to-sky hover:bg-sky ">
            {/* md:animate-typing md:overflow-hidden md:whitespace-nowrap md:border-r-4*/}
            <h1 className="   pr-5 text-lg md:text-xl text-white font-bold">{buttonText}</h1>
          </button>
        </div>
        <div data-aos="fade-left">
          <HomeImg />
        </div>
      </div>
      <div className="w-full flex justify-center md:pr-20 items-center bg-no-repeat bg-cover bg-home-learn-more md:h-[275px]" id={'asdfg'}>
        <div className="flex mt-24 justify-between items-center w-[80%] max-w-screen-xl">
          <h1 className="text-white md:text-2xl w-[50%]">
            Our services cater to organizations at every stage of development, starting from early bootstrapping and extending to the creation of long-term systems and applications.
          </h1>
          <button
            onClick={() => navigate('/contact')}
            className="py-3 px-10 md:px-20 text-l md:text-xl text-blue font-bold bg-white rounded-full hover:bg-blue hover:text-white">
            Learn more
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
