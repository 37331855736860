import { useNavigate } from 'react-router-dom';
import { ReactComponent as ProcessIcon1 } from '../assets/icons/process-1.svg';
import { ReactComponent as ProcessIcon2 } from '../assets/icons/process-2.svg';
import { ReactComponent as ProcessIcon3 } from '../assets/icons/process-3.svg';
import { ReactComponent as ProcessIcon4 } from '../assets/icons/process-4.svg';

type Props = {
  reference: React.MutableRefObject<HTMLDivElement | null>;
  scroll: (ref: string) => void;
};

const Process = ({ reference, scroll }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      ref={reference}
      className="w-full md:h-[900px] flex flex-col justify-center items-center bg-blue bg-process-bg bg-cover bg-no-repeat py-10 px-10 mt-10 md:py-0 md:mt-0 md:px-0">
      <div className="max-w-screen-xl flex justify-between items-center grid grid-cols-1 md:grid-cols-2" data-aos="fade-down">
        <div className="flex flex-col pb-10 md:pr-10">
          <h1 className="text-center md:text-left text-lightBlue font-bold text-5xl md:text-[68px] mb-5">Our Process</h1>
          <p className="text-white text-xl md:text-[27px] mb-10">
            {/* We’re committed to working with you at every step of the development lifecycle, from drafting the initial statement of work to executing product.S
            Our straightforward process ensures that you have complete control of the project at every stage while we handle talent acquisition, client intake,
            resource management, billing and insurance, and quality assurance. */}
            We are dedicated to supporting you throughout the entire development lifecycle, from creating the initial statement of work to delivering the final product. 
            Our streamlined process guarantees that you retain full control over the project at every stage. 
            This allows you to concentrate on growing your company while we handle essential tasks such as talent acquisition, client intake, resource management, billing and insurance, and quality assurance.
          </p>
          <div className="flex justify-start">
            <button
              onClick={() => navigate('/contact')}
              className="w-[100%] md:w-[50%] py-3 px-20 text-xl text-blue font-bold bg-white rounded-full hover:bg-sky hover:text-white">
              Learn more
            </button>
          </div>
        </div>
        <div className="flex flex-col space-y-10 md:w-[90%] mb-10 md:mb-0">
          <div className="flex space-x-5 items-center">
            <div>
              <ProcessIcon1 />
            </div>
            <p className="text-white text-xl md:text-[25px]">
              We work together to create a statement of work scoping your development needs.
            </p>
          </div>
          <div className="flex space-x-5 items-center">
            <div>
              <ProcessIcon2 />
            </div>
            <p className="text-white text-xl md:text-[25px]">
              We custom build a team of developers, sourcing talent with proficiencies in the programming languages and skills you need most.
            </p>
          </div>
          <div className="flex space-x-5 items-center">
            <div>
              <ProcessIcon3 />
            </div>
            <p className="text-white text-xl md:text-[25px]">
              Our team builds your product and regularly interfaces with you to ensure a high-quality build and alignment on scope.
            </p>
          </div>
          <div className="flex space-x-5 items-center">
            <div>
              <ProcessIcon4 />
            </div>
            <p className="text-white text-xl md:text-[25px]">
              Our team finishes your project and prepares it for your review. Once it’s good to go, we handle all the paperwork.
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Process;
