import React from 'react';
import { ReactComponent as OurMissionIcon } from '../assets/icons/ourMissionIcon.svg';
import { ReactComponent as OurApproachIcon } from '../assets/icons/ourApproachIcon.svg';
import { ReactComponent as OurTalentIcon } from '../assets/icons/ourTalentIcon.svg';

const AboutUs = ({ reference }: { reference: React.MutableRefObject<HTMLDivElement | null> }) => {
  return (
    <div ref={reference} className="w-full flex justify-center pt-16 pb-10">
      <div className="w-[80%] max-w-screen-xl">
        <h1 className="text-center text-blue font-bold text-5xl md:text-[65px]">About us</h1>
        <h2 className="text-center text-darkGray text-2xl md:text-[28px] pb-10 md:pb-0">
          Learn more about our development process
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 pb-10 md:pb-0">
          <div className="flex flex-col md:w-[100%] md:col-start-1 md:col-end-3" data-aos="fade">
            <div className="flex items-center">
              <OurMissionIcon />
              <h1 className="-ml-5 text-2xl md:text-[32px] font-bold text-darkGray ">Our Mission</h1>
            </div>
            {/* <p className="text-gray text-xl md:text-2xl md:pl-20">
              We know that finding dependable, high-quality development talent can be difficult for period after difficult.
              We founded Two Lights to help founders find top-flight talent at competitive rates.
              You come with the idea and our team will find a way to make it a reality, without breaking the bank.
            </p> */}
              <p className="text-gray text-xl md:text-2xl md:pl-20">
                We know that finding dependable, high-quality development talent can be difficult. 
                We founded Two Lights to help founders find top-flight talent at competitive rates. 
                You come with the idea and our team will find a way to make it a reality, without breaking the bank.
              </p>
          </div>
          <div className="drop-shadow-2xl " data-aos="fade-left">
            <img src={require('../assets/images/nave.png')} alt="" width={415} />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 pb-10 md:pb-0">
          <div className="drop-shadow-2xl order-2 md:order-1" data-aos="fade-right">
            <img src={require('../assets/images/database.png')} alt="" width={415} />
          </div>
          <div className="flex flex-col md:w-[100%] md:col-start-2 md:col-end-4 order-1 md:order-2" data-aos="fade">
            <div className="flex items-center">
              <OurApproachIcon />
              <h1 className="-ml-5 text-2xl md:text-[32px] font-bold text-darkGray ">Our Approach</h1>
            </div>
            <p className="text-gray text-xl md:text-2xl md:pl-20">
              We provide best-in-class support from start to finish. We work directly
              with you to help define your development needs, connect you with our 
              pipeline of qualified software development teams, and handle all the logistics.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 pb-10 md:pb-0">
          <div className="flex flex-col md:w-[100%] md:col-start-1 md:col-end-3" data-aos="fade">
            <div className="flex items-center">
              <OurTalentIcon />
              <h1 className="-ml-5 text-2xl md:text-[32px] font-bold text-darkGray ">Our Talent</h1>
            </div>
            <p className="text-gray text-xl md:text-2xl md:pl-20">
              We work with highly qualified, rigorously vetted developers active during U.S.
              business hours. Our standard teams come staffed with a Project Manager, a QA, and a
              mix of full-stack senior and junior developers proficient in a variety of programming languages.
            </p>
          </div> 
          <div className="drop-shadow-2xl" data-aos="fade-left">
            <img src={require('../assets/images/creative.png')} alt="" width={415} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
