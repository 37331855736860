import { useRef, useEffect, useState } from 'react';
import { AboutUs, Footer, Header, Process, Services } from './components';
import Home from './components/Home';
import { ToastProvider } from './components/Toast';
import Aos from 'aos';
import 'aos/dist/aos.css'
import Clients from './components/Clients';
import WhatDifferent from './components/WhatDifferent';
import Structure from './components/Structure';
import TecExpertise from './components/TecExpertise';
import DevelopmentTypes from './components/DevelopmentTypes';
import Buildings from './components/Buildings';
import Typewriter from "typewriter-effect";
import { useLocation } from "react-router-dom";

import ReactGA from 'react-ga';

function App() {
  ReactGA.initialize('G-9C7C8H2PYJ');
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const homeRef = useRef<null | HTMLDivElement>(null);
  const aboutRef = useRef<null | HTMLDivElement>(null);
  const servicesRef = useRef<null | HTMLDivElement>(null);
  const contactRef = useRef<null | HTMLDivElement>(null);
  const processRef = useRef<null | HTMLDivElement>(null);
  const clientsRef = useRef<null | HTMLDivElement>(null);

  

  const executeScroll = (elementPosition: number) => {
    const headerOffset = 80; // Header height
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const [showWelcome, setShowWelcome] = useState<boolean>(true);

  const scroll = (ref: string) => {
    switch (ref) {
      case 'home':
        const homePosition = homeRef?.current?.getBoundingClientRect().top;
        if (homePosition) executeScroll(homePosition);
        break;
      case 'about':
        const aboutPosition = aboutRef?.current?.getBoundingClientRect().top;
        if (aboutPosition) executeScroll(aboutPosition);
        break;
      case 'services':
        servicesRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;
      case 'contact':
        const contactPosition = contactRef?.current?.getBoundingClientRect().top;
        if (contactPosition) executeScroll(contactPosition);
        break;
      case 'process':
        const processPosition = processRef?.current?.getBoundingClientRect().top;
        if (processPosition) executeScroll(processPosition);
        break;
      case 'clients':
        const clientsPosition = clientsRef?.current?.getBoundingClientRect().top;
        if (clientsPosition) executeScroll(clientsPosition);
        break;
      default:
        break;
    }
  };

  const location = useLocation();  

  useEffect(() => {
    Aos.init({ duration: 1000 })
    setTimeout(() => {    
      // setShowWelcome(false);
      //   setTimeout(() => {
      //     if(location.state?.ref){
      //       scroll(location.state.ref);
      //       window.history.replaceState({},'')
      //     }
      //   }, 1000);
    }, 5000);
  }, [])

  window.onscroll = () => {
    if(showWelcome){
      changePageHome()
    }
 }

 const changePageHome = () =>{
  setShowWelcome(false);
      setTimeout(() => {
        if(location.state?.ref){
          scroll(location.state.ref);
          window.history.replaceState({},'')
        }
      }, 1000);
 }

  return (
    <ToastProvider>
      {
        showWelcome ?
          <div className='w-full h-[120vh] pt-15 flex flex-col justify-center items-center bg-welcome-bg bg-cover bg-no-repeat overflow-hidden'>
            <div className='bg-welcomeBox-bg w-[800px] h-[360px] flex flex-col justify-center items-center bg-no-repeat' data-aos="zoom-out-down">
              <p className='text-white text-2xl md:text-4xl '>
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("What Can We Build for You?")
                      .start();
                  }} />
              </p>
            </div>
            <div className='mt-20 cursor-pointer' onClick={changePageHome}>
              <img src={require('./assets/images/arrow.png')} alt="" className="scale-50 h-40 w-40"/>
            </div>
          </div> :
          <div className="relative overflow-hidden" >
            <Header scroll={scroll} isContactPage={false} />
            <div style={{height:'100px'}}></div>
            <Home reference={homeRef} scroll={scroll} />
            <AboutUs reference={aboutRef}  />
            <WhatDifferent />
            <TecExpertise />
            <Structure />
            <Services reference={servicesRef} />
            <DevelopmentTypes />
            <Process reference={processRef} scroll={scroll} />
            <Buildings />
            <Clients reference={clientsRef} scroll={scroll} />
            <Footer scroll={scroll} isContactPage={false} />
          </div>
      }

    </ToastProvider>
  );
}

export default App;
