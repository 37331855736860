
const Buildings = () => {
  return (
    <div
      // ref={reference}
      className="flex flex-col aling-center px-10 py-20">
      <h1 className="text-center text-blue font-bold text-5xl md:text-[68px] pb-20">What can we build for you?</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 pb-10 md:px-[15%] space-x-10 md:pb-40">
        <div className="flex flex-col justify-center">
          <p className="font-bold text-xl md:text-[28px] text-gray mb-5 ">
            We specialize in developing
            cost-effective turnkey software solutions and products for our clients.
          </p>
          <p className="font-bold text-xl md:text-[28px] text-gray mb-5 ">
            Our team has a wide breadth of
            experience and language
            familiarity, giving you the ﬂexibility
            to completely customize your
            development needs.
          </p>
          <p className="text-lg text-blue md:text-2xl mb-5 ">
            Some of our language expertise
          </p>
        </div>
        <div className="md:w-[100%] " data-aos="fade">
          <img src={require('../assets/images/languajes.png')} alt="" width={600} />
        </div>
      </div>
      <h1 className="text-center text-blue font-bold text-5xl md:text-[68px] pb-10" data-aos="fade-down">How we mitigate your risks</h1>
      <h2 className="md:px-40 text-center text-darkGray text-xl md:text-[28px] pb-10 md:pb-20" data-aos="fade-down" >
        Our team has a wide breadth of experience and language familiarity, giving you the ﬂexibility to completely customize your development needs.
      </h2>
      <div className=" grid grid-cols-1 md:grid-cols-5 space-x-4  " >
        <div className='flex flex-col items-center pb-4 '>
          <div className='static w-[150px] h-[150px] bg-whiteElipse-bg flex flex-col justify-center items-center'>
            <p>Phase 1</p>
          </div>
          <p className="text-center text-gray font-bold text-2xl py-2">Scope your Needs</p>
          <p className=" text-center text-gray text-xl">
            You provide us with a
            description of your project and requirements. Our team of tech leads will work to scope those requirements into a cohesive project plan</p>
        </div>

        <div className='flex flex-col items-center pb-4 '>
          <div className='static w-[150px] h-[150px] bg-whiteElipse-bg flex flex-col justify-center items-center'>
            <p>Phase 2</p>
          </div>
          <p className="text-center text-gray font-bold text-2xl py-2">Sign Some Documents</p>
          <p className=" text-center text-gray text-xl">
            After scoping your needs we will use our pricing tool to provide you with a transparent quote. If you accept then we send over a contract
          </p>
        </div>
        <div className='flex flex-col items-center pb-4'>
          <div className='static w-[150px] h-[150px] bg-blueElipse-bg flex flex-col justify-center items-center'>
            <p>Phase 3</p>
          </div>
          <p className="text-center text-gray font-bold text-2xl py-2">Staff and Plan</p>
          <p className=" text-center text-gray text-xl">
            Next, we will staff your team using our pipeline of talent and begin the initial project development
            send over a contract
          </p>
        </div>
        <div className='flex flex-col items-center pb-4'>
          <div className='static w-[150px] h-[150px] bg-blueElipse-bg flex flex-col justify-center items-center'>
            <p>Phase 4</p>
          </div>
          <p className="text-center text-gray font-bold text-2xl py-2">Execute Project</p>
          <p className=" text-center text-gray text-xl">
            Together, we will build your product and openly
            communicate throughout the development phase to ensure service quality and milestone achievement
          </p>
        </div>
        <div className='flex flex-col items-center '>
          <div className='w-[150px] h-[150px] bg-darkblueElipse-bg flex flex-col justify-center items-center'>
            <p>Phase 5</p>
          </div>
          <p className="text-center text-gray font-bold text-2xl py-2">Receive Your Product</p>
          <p className=" text-center text-gray text-xl">
            Upon delivery, we will ensure you are satisﬁed with your solution and that all knowledge is transferred to the appropriate internal team
          </p>
        </div>
      </div>
    </div>
  );
};

export default Buildings;
