import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

type Props = {
  reference: React.MutableRefObject<HTMLDivElement | null>;
  scroll: (ref: string) => void;
};

const Clients = ({ reference, scroll }: Props) => {

  return (
    <div className='w[100%] bg-[#F5F5F5] pt-10' ref={reference}>
      <h1 className="text-center text-blue font-bold text-3xl md:text-[45px]">Our clients</h1>
      <div className='w[100%]  grid place-items-center '>
        <div className=" w-[100%] md:w-[90%] lg:w-[50%] md:p-20 overflow">
          <Carousel showArrows={true} autoPlay infiniteLoop showStatus={false} >
            <div>
              <img src={require('../assets/images/client2.png')} alt="" width={100} />
            </div>
            <div >
              <img src={require('../assets/images/client1.png')} alt="" width={100} />
            </div>
            <div >
              <img src={require('../assets/images/client3.png')} alt="" width={100} />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  )
};

export default Clients;
