import styles from './headerStyles';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

const Header = ({ scroll, isContactPage }: { scroll: (ref: string) => void, isContactPage: boolean }) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false)

  return (
    //     style={{position:'fixed', width:'100%',backgroundColor:'white', zIndex:'1'}}
    <nav className="flex items-center justify-between flex-wrap p-6 text-right"  style={{position:'fixed', width:'100%',backgroundColor:'white', zIndex:'1'}}>
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <button onClick={() => { isContactPage ? navigate('/') : scroll('home') }}>
          <Logo />
        </button>
      </div>
      <div className="block lg:hidden">
        <button className={`flex items-center px-3 py-2  ${showMenu ? 'text-white bg-blue' : 'text-sky'} font-bold border rounded border-sky bg-transparent`} onClick={() => setShowMenu(prevState => !prevState)}>
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
        </button>
      </div>
      <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${!showMenu && 'hidden'} `}>
        <div className="text-sm lg:flex-grow">
          <button className={styles.button} onClick={() =>  isContactPage ? navigate('/',{'state':{'ref':'about'}}) : scroll('about')}>
            About Us
          </button>
          <button className={styles.button} onClick={() =>  isContactPage ? navigate('/',{'state':{'ref':'services'}}) : scroll('services')}>
            Our Services
          </button>
          <button className={styles.button} onClick={() =>  isContactPage ? navigate('/',{'state':{'ref':'process'}}) :  scroll('process')}>
            Our Process
          </button>
          <button className={styles.button} onClick={() =>  isContactPage ? navigate('/',{'state':{'ref':'clients'}}) : scroll('clients')}>
            Our Clients
          </button>
          <button className={styles.outlinedButton} onClick={() => navigate('/contact')}>
            Contact Us
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Header;
