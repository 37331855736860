
const DevelopmentTypes = () => {
  return (
    <div
      // ref={reference}
      className="flex flex-col aling-center px-10 pb-20">
      <h1 className="text-center text-blue font-bold text-3xl md:text-[58px] pb-20">Some ways we can help you</h1>
      <div className="w[100%]  flex  justify-center aling-center pb-10" data-aos="fade-left">
        <img src={require('../assets/images/devTypes.png')} alt="" width={800} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 pb-10 md:px-[15%] space-x-10 ">
        <div className="flex flex-col justify-center" data-aos="fade-right">
          <h1 className="text-center md:text-left font-bold text-xl md:text-[40px] mb-5">Machine learning</h1>
          <p className="text-xl md:text-[24px] text-gray font-bold mb-5 ">
            {/* We build interpretable data models and algorithms to predict time series, classify, segment, and automate while optimizing your decision making processes. */}
            We develop interpretable data models and algorithms that predict time series, classify data, segment information, and automate processes, all aimed at optimizing your decision-making.
          </p>
          <p className="text-xl md:text-[24px] text-gray mb-5 ">
            {/* We create machine learning models that allows us to predict particular events in the future based on data from the past. */}
            We also build machine learning models that enable accurate predictions of future events based on historical data.
          </p>
        </div>
        <div className="md:w-[100%] " data-aos="fade-left">
          <img src={require('../assets/images/machineLerning.png')} alt="" width={600} />
        </div>
      </div>
      <h1 className="text-center text-blue font-bold text-3xl md:text-[45px] pb-8">Mobile development</h1>
      <h2 className="md:px-40 text-center text-darkGray text-xl md:text-[28px] pb-10 md:pb-20">
        {/* We have experienced and skilled software engineers for the development process for both "native and hybrid" mobile solutions,
        as well as DevOps and UX/UI profiles which completes the "software engineering combo" */}
        We offer both native and hybrid mobile solutions, DevOps, and UX/UI profiles that can efficiently develop and deploy your solution, whether you're in the idea phase or nearing final execution.
      </h2>
      <div className="w[100%] flex  justify-center aling-center pb-20" data-aos="fade-left">
        <img src={require('../assets/images/devTecs.png')} alt="" width={600} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 pb-10 md:px-[15%] space-x-10 ">
        <div className="flex flex-col justify-center">
          <h1 className="text-center md:text-left font-bold text-xl md:text-[40px] mb-5">Data engineering</h1>
          <p className="text-xl md:text-[24px] text-gray mb-5 ">
            {/* End-to-end custom software solutions that lead our clients to access, accommodate, model and exploit data in order to improve their business and operational strategies. */}
            We develop end-to-end custom solutions that help our clients to access, accommodate, model and leverage data in order to improve their business and operational strategies.
          </p>
          <p className="text-xl md:text-[24px] text-gray mb-5 ">
            {/* We focus on detecting anomalies and identifying patterns and correlations, making them visible to our clients. */}
            We focus on detecting hidden and overlooked anomalies and identifying patterns and correlations, making them visible to our clients.
          </p>
          <p className="text-xl md:text-[24px] text-gray mb-5 ">
            {/* We help businesses to become data-driven organizations transforming their data into their profit. */}
            We help businesses to become data-driven organizations, transforming their data into their profit.
          </p>
        </div>
        <div className="md:w-[100%] " data-aos="fade">
          <img src={require('../assets/images/dataEngineering.png')} alt="" width={600} />
        </div>
      </div>
    </div>
  );
};

export default DevelopmentTypes;
