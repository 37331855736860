import { useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const CAPTCHA_KEY = '6LcuhnQjAAAAADSTmvgcT83OcsWLohV4tTAETTfg';

const validationSchema = yup.object({
  name: yup.string().required('Name required'),
  email: yup.string().email('invalid format').required('Email required'),
  message: yup.string().required('Message required'),
  token: yup.string().required('validation required').nullable(),
  companyName: yup.string().required('Company Name required'),
  option: yup.string().required('Option required'),
});

const initialValues: {
  name: string;
  email: string;
  message: string;
  option: string;
  token: string | null;
  projectLength: string;
  projectLanguage: string;
  resourceTime: string;
  companyName: string;
} = {
  name: '',
  email: '',
  message: '',
  option: '',
  token: '',
  projectLength: '',
  projectLanguage: '',
  resourceTime: '',
  companyName: '',
};

const Contact = () => {
  let captcha: any;
  // const { add } = useToast();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log('values:', values);
      emailjs
        .send('service_qy47mqq', 'main', values, 'ozJkYVFKjecJnUVY_')
        .then(() => showMessage())
        .catch(() => setMessageNotSended(true))
        .finally(() => {
          resetForm();
          captcha.reset();
        });
    },
  });

  const showMessage = () => {
    setMessageSended(true);
    setTimeout(() => {
      setMessageSended(false);
      navigate('/form-submission');
    }, 1000);
  };

  const handleChange = (newToken: string | null) => formik.setFieldValue('token', newToken);
  const [messageSended, setMessageSended] = useState(false);
  const [messageNotSended, setMessageNotSended] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <div className="w-full flex justify-center py-16 mb-20 ">
        <div className="w-[80%] max-w-screen-xl" data-aos="fade-up">
          <div style={{ height: '100px' }}></div>
          <h1 className="text-center text-blue font-bold text-[45px]">Contact us</h1>

          <h2 className="text-center text-[28px] text-darkGray mb-8">
            Get in touch with us to learn more.
          </h2>
          <div className="py-10 flex justify-center bg-gradient-to-b from-lightBlue to-blue rounded-[50px] drop-shadow-2xl">
            <div className="w-[80%] min-h-[600px] flex flex-col">
              <p className="text-2xl text-white mb-4">
                <b className="font-bold">Want to learn more?</b> Get in touch.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="w-full">
                  <label className="text-lg text-white">Name*</label>
                  <input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className="bg-transparent border border-white text-white text-sm rounded-lg w-full p-2.5"
                    required
                  />
                  {formik.touched.name && formik.errors.name && (
                    <span className="flex items-center tracking-wide text-red-500 text-md mt-1 ml-1">
                      {formik.errors.name}
                    </span>
                  )}
                </div>
                <div className="w-full">
                  <label className="text-[18px] text-white">Email*</label>
                  <input
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    type="text"
                    className="bg-transparent border border-white text-white text-sm rounded-lg w-full p-2.5"
                    required
                  />
                  {formik.touched.email && formik.errors.email && (
                    <span className="flex items-center tracking-wide text-red-500 text-md mt-1 ml-1">
                      {formik.errors.email}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full mt-4">
                <label className="text-[18px] text-white">
                  Please provide a brief description of the services you need:*
                </label>
                <textarea
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  className="bg-transparent border border-white text-white text-sm rounded-lg w-full p-2.5"
                  rows={4}
                  required
                />
                {formik.touched.message && formik.errors.message && (
                  <span className="flex items-center tracking-wide text-red-500 text-md ml-1">
                    {formik.errors.message}
                  </span>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div className="w-full">
                  <label className="text-lg text-white">Estimated Project Length</label>
                  <input
                    type="text"
                    name="projectLength"
                    value={formik.values.projectLength}
                    onChange={formik.handleChange}
                    className="bg-transparent border border-white text-white text-sm rounded-lg w-full p-2.5"
                  />
                </div>
                <div className="w-full">
                  <label className="text-[18px] text-white">Primary Project Language</label>
                  <input
                    name="projectLanguage"
                    value={formik.values.projectLanguage}
                    onChange={formik.handleChange}
                    type="text"
                    className="bg-transparent border border-white text-white text-sm rounded-lg w-full p-2.5"
                  />
                </div>
                <div className="w-full">
                  <label className="text-[18px] text-white mt-4">Resource time needed</label>
                  <div>
                    <select
                      name="resourceTime"
                      value={formik.values.resourceTime}
                      onChange={formik.handleChange}
                      required
                      className="bg-blue border border-white text-white text-sm rounded-lg w-[100%] p-3">
                      <option value="-">-</option>
                      <option value="FullTime">Full-time Resource</option>
                      <option value="PartTime">Part-time Resource</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-full mt-4">
                <label className="text-lg text-white">Company Name*</label>
                <input
                  type="text"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  className="bg-transparent border border-white text-white text-sm rounded-lg w-full p-2.5"
                  required
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <span className="flex items-center tracking-wide text-red-500 text-md mt-1 ml-1">
                    {formik.errors.companyName}
                  </span>
                )}
              </div>
              <label className="text-[18px] text-white mt-4">How did you hear about us?*</label>
              <div>
                <select
                  name="option"
                  value={formik.values.option}
                  onChange={formik.handleChange}
                  placeholder="How did you hear about us?"
                  required
                  className="bg-blue border border-white text-white text-sm rounded-lg w-[100%] md:w-[48%] p-3">
                  <option value="">-</option>
                  <option value="Referral">Referral</option>
                  <option value="Google search">Google search</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Word of mouth">Word of mouth</option>
                  <option value="Other">Other</option>
                </select>
                {formik.touched.option && formik.errors.option && (
                  <span className="flex items-center tracking-wide text-red-500 text-md mt-1 ml-1">
                    {formik.errors.option}
                  </span>
                )}
              </div>
              <div className="mt-4">
                <ReCAPTCHA
                  ref={(e) => (captcha = e)}
                  sitekey={CAPTCHA_KEY}
                  onChange={handleChange}
                  size={'normal'}
                />
                {formik.touched.token && formik.errors.token && (
                  <span className="flex items-center tracking-wide text-red-500 text-md ml-1">
                    {formik.errors.token}
                  </span>
                )}
              </div>
              <button
                className="py-2 mt-4 w-[250px] text-xl text-blue font-bold bg-white rounded-full hover:bg-sky hover:text-white"
                onClick={() => formik.handleSubmit()}>
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
      {messageSended && (
        <div
          className="fixed bottom-5 left-5 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded"
          role="alert"
          data-aos="fade-top">
          <strong className="font-bold">Thank you for your message. </strong>
          <span className="block sm:inline pr-8">
            {' '}
            A member of our team will be in touch with you shortly.
          </span>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => setMessageSended(false)}>
            <svg
              className="fill-current h-6 w-6 text-green-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}
      {messageNotSended && (
        <div
          className="fixed bottom-5 left-5 bg-red-100 border border-red-400 text-black-700 px-4 py-3 rounded"
          role="alert"
          data-aos="fade-top">
          <strong className="font-bold">Error! </strong>
          <span className="block sm:inline pr-8"> Message not sent.</span>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => setMessageNotSended(false)}>
            <svg
              className="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}
    </>
  );
};

export default Contact;
