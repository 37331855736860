import { ReactComponent as RectanguleIcon } from '../assets/icons/rectangle45.svg';

const WhatDifferent = () => {

  return (
    <div
      className="w-full md:min-h-[100vh] md:px-[10%] flex flex-col justify-center items-center bg-[#ECF5F9] bg-cover bg-no-repeat py-10 px-10 mt-10 md:py-0 md:mt-0 md:px-0"
    >
      <div className="max-w-screen-xl flex justify-between items-center grid grid-cols-1 md:grid-cols-2" data-aos="fade-down">
        <div className="flex flex-col space-y-10 md:w-[90%] mb-10 md:mb-0">
          <h1 className="text-center md:text-left text-lightBlue font-bold text-5xl md:text-[65px] mb-5">What makes us different</h1>
          <div className="flex space-x-5 items-center">

            <div>
              <RectanguleIcon />
            </div>
            <p className="text-xl md:text-[24px]">
              We are able to staff virtually any project using
              our large pipeline of developers.
            </p>
          </div>
          <div className="flex space-x-5 items-center">
            <div>
              <RectanguleIcon />
            </div>
            <p className="text-xl md:text-[24px]">
              From scoping to project commencement, our developers can start working in as little as two weeks.
            </p>
          </div>
          <div className="flex space-x-5 items-center">
            <div>
              <RectanguleIcon />
            </div>
            <p className="text-xl md:text-[24px]">
              Our teams follow all industry best practices and leverage the latest emerging technologies to help you achieve your vision.
            </p>
          </div>
          <div className="flex space-x-5 items-center">
            <div>
              <RectanguleIcon />
            </div>
            <p className="text-xl md:text-[24px]">
              We offer services at highly competitive prices, charging 70% less than US-domestic competitors and up to 20% less than offshore competitors.
            </p>
          </div>
        </div>
        <div className="relative h-[400px] md:h-[320px] md:w-[500px] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-sky from-24% via-sky via-1% to-[#ECF5F9] to-75%">
          <div className='absolute md:top-0 md:right-0 w-[374px] h-[205px] bg-1bubble-bg text-center pt-[80px]'>
            <p className="text-white text-xl md:text-[20px] px-[40px]">
              We can help you build
              virtually anything
            </p>

          </div>
          <div className='absolute bottom-28 md:bottom-16 md:left-0 w-[266px] h-[145px] bg-2bubble-bg text-center pt-[60px] z-10'>
            <p className="text-white text-xl md:text-[20px]">
              We are
              sonic fast
            </p>
          </div>
          <div className='absolute bottom-0 right-0 md:right-20 w-[236px] h-[155px] bg-3bubble-bg text-center pt-[75px] z-20'>
            <p className="text-white text-xl md:text-[20px]">
              We are
              accessible
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default WhatDifferent;
