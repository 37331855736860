const styles = {
  root: 'z-10 sticky top-0 w-full bg-white flex justify-center h-20 drop-shadow-lg',
  container: 'w-[80%] max-w-screen-xl flex justify-between items-center',
  button:
    'block mt-4 lg:inline-block lg:mt-0 px-6 text-gray font-medium hover:text-blue hover:font-bold',
  outlinedButton:
    'block mt-4 lg:inline-block lg:mt-0 py-2 px-14 text-sky font-bold hover:text-white border border-sky bg-transparent hover:bg-sky rounded-full',
};

export default styles;
