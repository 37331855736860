
const cards = [
  { header: 'Our technical partners have', title: '25+ YOE', subtitle: '(In the IT industry)' },
  { header: 'We can speak on', title: '15+ Specialties', subtitle: '(ERP design, web design, ML, AI)' },
  { header: 'We staff', title: '100+ Developers', subtitle: '(with a pipeline to staff additional resources proficient in any language)' },
  { header: 'We partner with', title: '20+ Firms', subtitle: '(With relationships ranging from advisory to investment capacities)' },
  { header: 'We maintain', title: '4+ Facilities', subtitle: '(US, Argentina, Uruguay, Spain)' },
  { header: 'We’ve built our clients', title: '75+ Solutions', subtitle: '(and counting!)' }]


const TecExpertise = () => {

  return (
    <div
      className="md:min-h-[100vh] flex flex-col items-center bg-blue bg-tecExpertise-bg bg-cover bg-no-repeat ">
      <div className="p-10 md:pt-40" data-aos="fade-left">
        <p className="text-white text-lg md:text-[55px]">
          Our technical expertise
        </p>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3" data-aos="fade-left">
        {
          cards.map((card) =>
            <div key={card.title} className='w-[160px] h-[250px] md:w-[200px] md:h-[270px] p-2 m-4 md:m-4 flex flex-col items-center justify-center text-center border-2 border-white rounded-lg backdrop-blur-md'>
              <p className="text-white text-md md:text-xl">{card.header}</p>
              <p className="text-sky text-lg md:text-3xl">{card.title}</p>
              <p className="text-white text-md md:text-xl">{card.subtitle}</p>
            </div>
          )}
      </div>
    </div>
  );
};

export default TecExpertise;
