import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { ReactComponent as MailIcon } from '../assets/icons/mail.svg';
import { ReactComponent as LinkedInIcon } from '../assets/icons/linkedin.svg';
import { useNavigate } from "react-router-dom";

const Footer = ({ scroll, isContactPage }: { scroll: (ref: string) => void, isContactPage: boolean }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col items-center justify-between pt-10 md:h-[300px] bg-[#EBF8FD]">
      <div className="w-[80%] max-w-screen-xl flex justify-between grid grid-cols-1 md:grid-cols-5">
        <div>
          <Logo />
        </div>
        {!isContactPage &&
          <>
          <div className="flex flex-col items-start"> 
            <div className="flex items-start mt-2">
              <h1 className="text-xl text-blue cursor-pointer" onClick={() => scroll('about')}>
                About us
              </h1>
            </div>
            <div className="flex items-start mt-2">
              <h1 className="text-xl text-blue cursor-pointer"  onClick={() => scroll('services')}>
                Our Services
              </h1>
            </div>
            <div className="flex items-start mt-2">
              <h1 className="text-xl text-blue cursor-pointer" onClick={() => scroll('process')}>
                Our Process
              </h1>
            </div>
            <div className="flex flex-col items-start mt-2">
              <h1 className="text-xl text-blue cursor-pointer" onClick={() => scroll('clients')}>
                Our Clients
              </h1>
            </div>
            <div className="flex flex-col items-start mt-2">
              <h1 className="text-xl text-blue cursor-pointer" onClick={() => navigate('contact')}>
                Contact Us
              </h1>
            </div>
          </div>
            {/* <div className="flex flex-col items-start">
              <h1 className="text-xl text-blue cursor-pointer" onClick={() => scroll('about')}>
                About us
              </h1>
            </div>
            <div className="flex flex-col items-start cursor-pointer" onClick={() => scroll('services')}>
              <h1 className="text-xl text-blue">Our Services</h1>
            </div>
            <div className="flex flex-col items-start">
              <h1 className="text-xl text-blue cursor-pointer" onClick={() => scroll('process')}>
              Our Process
              </h1>
            </div>
            <div className="flex flex-col items-start">
              <h1 className="text-xl text-blue cursor-pointer" onClick={() => scroll('clients')}>
              Our Clients
              </h1>
            </div>
            <div className="flex flex-col items-start">
              <h1 className="text-xl text-blue cursor-pointer" onClick={() => navigate('contact')}>
              Contact Us
              </h1>
            </div> */}
          </>
        }
        <div className="flex flex-col items-start space-y-2 mt-2  ">
          {/* <h1 className="text-xl text-blue">Get in touch</h1> */}
          <div className="flex space-x-1 items-center pl-1">
            <MailIcon />
            <a className="text-base text-blue font-light pl-1" href="mailto:contact@twolights.dev">contact@twolights.dev</a>
            
          </div>
          <div className="flex space-x-1 items-center cursor-pointer " onClick={() => window.open('https://www.linkedin.com/company/two-lights-llc/', '_blank')}>
            <LinkedInIcon />
            <p className="text-base text-blue font-light">Two Lights</p>
          </div>
        </div>
      </div>
      <p className='pt-4'>© 2023, Two Lights LLC. All Rights Reserved</p>
    </div>
  );
};

export default Footer;
